import React from 'react';

import { batch } from 'react-redux';

import { HeaderSideMenuTabs } from './HeaderSideMenuTabs';
import { BlogIcon } from '../atoms/Icons/Styleguide/BlogIcon';
import { SearchIcon } from '../atoms/Icons/Styleguide/SearchIcon';
import { ShopIcon } from '../atoms/Icons/Styleguide/ShopIcon';
import { SupportIcon } from '../atoms/Icons/Styleguide/SupportIcon';
import { SubscriptionTabIcon } from '../FigmaStyleguide/Icons/SubscriptionTabIcon';
import { SubscriptionSource } from '../models/Subscription/SubscriptionData';
import { Analytics } from '../services/Analytics/Analytics';
import { LocalStorageService } from '../services/LocalStorage';
import { setSideMenuActivePage } from '../store/ducks/layout';
import { GemsAnalyticsShopLocations, setGemsShopLocation } from '../store/ducks/leanplum/lpAnalytics';
import { setSubscriptionSource } from '../store/ducks/subscription/common';

export function getHeaderTabButtons(dispatch, currentLang) {
    const buttons = [
        {
            //search tab
            onClick: () => {
                dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SEARCH_TAB));
                Analytics.trackEvent(Analytics.newHome.searchNavClick('search'));
            },
            dataElementDescription: 'nav-search-button',
            icon: <SearchIcon />,
            keyName: 'SIDEBAR_SEARCH',
        },
        {
            //advantage tab
            onClick: () => {
                Analytics.trackEvent(Analytics.newHome.searchNavClick('subscription'));
                Analytics.trackEvent(Analytics.general.topNavAdvantage());
                batch(() => {
                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SUBSCRIPTION_TAB));
                    dispatch(setSubscriptionSource(SubscriptionSource.HEADER));
                });
            },
            dataElementDescription: 'nav-advantage-button',
            icon: <SubscriptionTabIcon />,
            keyName: 'SUBSCRIPTION_TAB',
        },
        {
            //shop tab
            onClick: () => {
                batch(() => {
                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.SHOP_TAB));
                    dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.COMMON_HEADER_ICON));
                });
                LocalStorageService.setItem('shopOpenRequest', GemsAnalyticsShopLocations.COMMON_HEADER_ICON);
                Analytics.trackEvent(Analytics.newHome.searchNavClick('shop'));
            },
            dataElementDescription: 'nav-shop-button',
            icon: <ShopIcon />,
            keyName: 'SIDEBAR_SHOP',
        },
        {
            //support tab
            onClick: () => {
                dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
                Analytics.trackEvent(Analytics.newHome.searchNavClick('support'));
            },
            dataElementDescription: 'nav-support-button',
            icon: <SupportIcon />,
            keyName: 'SIDEBAR_SUPPORT',
        },
    ];
    if (currentLang === 'en') {
        buttons.push({
            //blog tab
            onClick: () => {
                Analytics.trackEvent(Analytics.newHome.searchNavClick('blog'));
                window.location.href = '/blog/';
            },
            dataElementDescription: 'nav-blog-button',
            icon: <BlogIcon />,
            keyName: 'SIDEBAR_BLOG',
        });
    }
    return buttons;
}

import React, { MouseEventHandler, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './NewHeader.css';
import { StringUtils } from '../../../utils/StringUtils';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { DividerIcon } from '../../atoms/Icons/Styleguide/DividerIcon';
import { ArkadiumIcon } from '../../atoms/Icons/Styleguide/MobileNavigationIcons/ArkadiumLogo';
import { getHeaderMenuLinks } from '../../constants/HeaderMenuLinks';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { getHeaderTabButtons } from '../../constants/HeaderTabButtons';
import { PageTypes } from '../../constants/Pages';
import { SignInUpComponents } from '../../constants/SignInUpComponents';
import { AvatarGemsCounter } from '../../molecules/AvatarGemsCounter/AvatarGemsCounter';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import UserService from '../../services/UserService';
import { setSideMenuActivePage, setSignInUpState } from '../../store/ducks/layout';
import {
    GemsAnalyticsRegistrationLocations,
    setSocialRegistrationLocation,
} from '../../store/ducks/leanplum/lpAnalytics';
//new header with abtest
const NewHeader = React.memo((props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const activeUserSubscriptions = useSelector((state) => state.activeUserSubscriptions);
    const currentLang = useSelector((state) => state.currentLang);
    const currentPageType = useSelector((state) => state.pageType);
    const currentCategoryPageName = useSelector((state) => state.categoryPageName);
    const categoryPromoData = useSelector((state) => state.categoryPromoData);
    const pageType = useSelector((state) => state.pageType);

    const hideHeader: boolean = pageType === PageTypes.Game;

    const menuLinks = getHeaderMenuLinks(t, currentLang);
    const tabButtons: TabButtonProps[] = getHeaderTabButtons(dispatch, currentLang);
    // const isUserLoggedIn = UserService.isUserLoggedIn();

    const categoryPromoMenu = {
        title: categoryPromoData?.categoryName,
        link: UrlService.createURL(
            `/${UrlService.getPageLocalizedRoute(currentLang, PageTypes.Category)}/${categoryPromoData?.categorySlug}/`
        ),
        categoryPageName: categoryPromoData?.categoryName,
    };

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(null);
    const handleNewHeaderZoomed = (e) => {
        // recalculate when user resumes control/command btn after clicking +, - or =
        if (e.key !== 'Control' && e.key !== 'Meta') {
            return;
        }
        const platform: string = window.navigator.platform;
        const isMac: boolean = platform && !!(platform.toString().match(/^Mac/gi) || platform.toString().match(/^i/gi));
        const isRetinaDisplay = (): boolean => {
            const maybeDefaultPixelRatio = window.devicePixelRatio || 1;

            return isMac && maybeDefaultPixelRatio >= 2;
        };
        const defaultPixelRatio: number = isRetinaDisplay() ? 2 : 1;
        const isZoomed = window.devicePixelRatio && window.devicePixelRatio !== defaultPixelRatio;
        const isMobile = window.innerWidth <= 1024;
        if (isZoomed || isMobile) {
            document.body.setAttribute('__zoom_used', '');
        } else {
            document.body.removeAttribute('__zoom_used');
        }
    };

    useEffect(() => {
        document.body.setAttribute('__new_header', ''); // mark new header for styles
        const adFree = user && activeUserSubscriptions?.length;
        if (adFree) {
            document.body.setAttribute('__adfree', '');
        } else {
            document.body.removeAttribute('__adfree');
        }
        handleNewHeaderZoomed({ key: 'Control' }); // for case if page is already zoomed on load
        window.addEventListener('keyup', handleNewHeaderZoomed); // handle zoom on user zoomed on control/command btn
        window.addEventListener('resize', handleNewHeaderZoomed); // handle zoom on window resize
        return () => {
            document.body.removeAttribute('__new_header');
            document.body.removeAttribute('__adfree');
            window.removeEventListener('keyup', handleNewHeaderZoomed);
            window.removeEventListener('resize', handleNewHeaderZoomed);
        };
    }, [user, activeUserSubscriptions]);

    const trackClick = (category: string, index: number) => {
        Analytics.trackEvent(Analytics.general.topNavClick(category, index));
    };

    const isMenuLinkActive = ({ title, categoryPageName }) => {
        const isHomePage = currentPageType === PageTypes.Home && StringUtils.equalIgnoreCase(title, t('HOME_PAGE'));
        const acceptedCategoryPageTypes: string[] = [PageTypes.AllCategories, PageTypes.Category];
        const isCategoryPage =
            acceptedCategoryPageTypes.includes(currentPageType) && categoryPageName === currentCategoryPageName;
        return isHomePage || isCategoryPage || (currentPageType == PageTypes.BlogArchive && title == 'blog');
    };

    const openLoginSidebar = (signInUpState: SignInUpComponents) => {
        if (signInUpState === SignInUpComponents.SIGN_UP) {
            Analytics.trackEvent(Analytics.profile.createAccountButton());
        }
        batch(() => {
            dispatch(setSignInUpState(signInUpState));
            dispatch(setSideMenuActivePage(HeaderSideMenuTabs.LOG_IN_TAB));
            dispatch(setSocialRegistrationLocation(GemsAnalyticsRegistrationLocations.HEADER_BUTTON));
        });
    };

    const ProfileOrActions = () => {
        const element = isUserLoggedIn ? (
            <AvatarGemsCounter />
        ) : (
            <span className={styles.profileButtons}>
                <button
                    className={styles.outline}
                    type="button"
                    onClick={() => openLoginSidebar(SignInUpComponents.SIGN_IN)}
                >
                    <I18nText keyName="SIGN_IN" />
                </button>
                <button
                    className={styles.primary}
                    type="button"
                    onClick={() => openLoginSidebar(SignInUpComponents.SIGN_UP)}
                >
                    <I18nText keyName="CREATE_A_FREE_PROFILE" className={styles.mobileHidden} />
                    <I18nText keyName="CREATE_PROFILE" className={styles.mobileOnly} />
                </button>
            </span>
        );

        return element;
    };

    const shouldMenuItemHighlight = (menuItem): boolean => {
        if (pageType === PageTypes.Home && StringUtils.equalIgnoreCase(menuItem.title, t('HOME_PAGE'))) {
            return true;
        }
        if (
            (pageType === PageTypes.AllCategories || pageType === PageTypes.Category) &&
            menuItem.categoryPageName === currentCategoryPageName
        ) {
            return true;
        }

        return false;
    };

    const getIsUserLoggedIn = () => {
        setIsUserLoggedIn(UserService.isUserLoggedIn());
    };

    useEffect(() => {
        getIsUserLoggedIn();
    }, [user]);

    const NewHeaderContent = () => {
        const t = props.pageType;

        // @ts-ignore
        return (
            <div
                className={classNames({
                    [styles.mobileHidden]: t === PageTypes.Game,
                    [styles.hidden]: t === PageTypes.Game && hideHeader,
                })}
            >
                <header className={styles.header}>
                    <a href="/" aria-label="Go to the homepage">
                        <ArkadiumFullLogo
                            textHoverColor="#dc1e34"
                            arrowHoverColor="#dc1e34"
                            focusable={false}
                            ariaHidden={true}
                            role="presentation"
                            className={styles.mobileHidden}
                        />
                        <ArkadiumIcon className={classNames(styles.mobileOnly, styles.mobileHomeIcon)} />
                    </a>
                    <ProfileOrActions />
                </header>
                <nav className={classNames(styles.subHeader)}>
                    <span className={styles.categoryLinks}>
                        {menuLinks.map((l) => (
                            <a
                                key={l.title}
                                className={classNames({ [styles.active]: isMenuLinkActive(l) })}
                                onClick={() => trackClick(l.categoryPageName, 0)}
                                href={l.link}
                                data-title={l.title}
                                data-testid={l.categoryPageName}
                            >
                                {l.title}
                            </a>
                        ))}
                        {currentLang === 'en' && categoryPromoData && categoryPromoData?.categoryName?.length > 0 && (
                            <a
                                key={categoryPromoMenu.title}
                                data-testid={categoryPromoMenu.categoryPageName}
                                className={classNames(shouldMenuItemHighlight(categoryPromoMenu) && styles.active)}
                                onClick={() => trackClick(categoryPromoMenu.categoryPageName, 0)}
                                href={categoryPromoMenu.link}
                            >
                                {categoryPromoMenu.title}
                            </a>
                        )}
                    </span>
                    <span className={styles.sidebarButtons}>
                        {tabButtons.map((b, i) => (
                            <>
                                {b.keyName === 'SIDEBAR_BLOG' && <DividerIcon />}
                                <TabButton key={i} {...b} />
                            </>
                        ))}
                    </span>
                </nav>
            </div>
        );
    };

    return <NewHeaderContent />;
});

type TabButtonProps = {
    onClick: MouseEventHandler<any>;
    icon: JSX.Element;
    keyName: string;
    dataElementDescription: string;
};
const TabButton = React.memo(({ onClick, icon, keyName, dataElementDescription }: TabButtonProps) => {
    return (
        <button type="button" onClick={onClick} data-element-description={dataElementDescription}>
            <div role="presentation">{icon}</div>
            <I18nText as="p" keyName={keyName} />
        </button>
    );
});

export default NewHeader;
